import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import getTheme from '../../molecules/Section/utils/getTheme';
import HeaderStyles, {
  NavButtons,
} from '../../styles/Components/Header/HeaderStyles';
import Icon from '../../styles/atoms/icons';
import Link from '../../utils/Link';
import useOutsideClick from '../../utils/useOutsideClick';
import DropdownContent from './DropdownContent';
import MobileNav from './components/MobileNav';
import NavToggle from './components/NavToggle';
import AnnouncementV2 from '../AnnouncementV2';

const Header = ({
  withAnnouncement,
  disableLogoNavigation,
  dynamicMenu,
  dynamicCallToAction,
  componentName,
  isDark,
  nextComponentBg,
}) => {
  const {
    data: { menu, callToAction, announcementComponent, ...rest },
  } = useStaticQuery(getData);
  const [menuData] = useState(menu || dynamicMenu);
  const [callToActionData] = useState(callToAction || dynamicCallToAction);
  const [popUpReference] = useState(
    callToAction.popupReference || dynamicCallToAction?.popupReference
  );
  const [mobileNav, setMobileNav] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const SCROLL_THRESHOLD = 50;
  const [changeNav, setChangeNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [isExpanded, setExpaned] = useState(false);
  const dropdownRef = useRef();
  const [[menuIndex, direction], setMenuIndex] = useState([-1, 0]);
  const size = useWindowSize();
  const loc = useLocation();
  const isStripeCaptialPage = loc.pathname.includes('capital');
  const isDarkTheme = nextComponentBg
    ? getTheme(nextComponentBg).theme === 'dark'
    : isDark;
  const desktopLogoId = `sm-${
    isDarkTheme && !changeNav && (size.width ? size.width >= 1200 : isDarkTheme)
      ? 'light'
      : 'dark'
  }-logo`;

  const submenuList = useMemo(
    () =>
      menuData &&
      menuData.menuItem.map((item, index) => {
        const length = item.childMenuItem.length;
        if (length > 0) {
          return (
            <div className="dropdown-content mx-auto" data-index={index}>
              <DropdownContent item={item} nowrap />
            </div>
          );
        } else {
          return <div />;
        }
      }),
    [menuData]
  );

  const variants = {
    enter: dir => {
      let x = 0;
      if (dir > 0) {
        x = 50;
      } else if (dir < 0) {
        x = -50;
      }
      return {
        x,
        y: dir === 0 ? 20 : 0,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      y: 0,
      opacity: 1,
    },
    exit: dir => {
      let x = 0;
      if (dir > 0) {
        x = -50;
      } else if (dir < 0) {
        x = 50;
      }
      return {
        zIndex: 0,
        x,
        y: dir === 0 ? -20 : 0,
        opacity: 0,
      };
    },
  };

  const handleMenu = (e, index) => {
    if (index !== -1) {
      setMenuIndex([index, menuIndex !== -1 ? index - menuIndex : 0]);
    } else if (e.clientY < SCROLL_THRESHOLD) {
      setMenuIndex([-1, 0]);
    }
  };
  const handleMouseInSubmenu = flag => {
    if (!flag) {
      setMenuIndex([-1, 0]);
    }
  };
  const scrollHandler = () => {
    if (window.scrollY > 1) {
      setChangeNav(true);
    } else {
      setChangeNav(false);
    }
  };
  const handleHeader = flag => {
    if (flag) {
      document.querySelector('.submenu-wrapper').classList.remove('d-xl-none');
    } else {
      setTimeout(() => {
        document.querySelector('.submenu-wrapper').classList.add('d-xl-none');
      }, 200);
    }
  };
  const handleToggle = e => {
    setExpaned(e);
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  useEffect(() => {
    if (menuIndex !== -1) {
      const dom = document.querySelector(
        `.submenu-wrapper .dropdown-content[data-index='${menuIndex}']`
      );
      const container = document.querySelector('.submenu-container');
      const arrow = document.querySelector('.site-header-arrow');

      if (dom) {
        document
          .querySelector('.submenu-wrapper')
          .classList.remove('hidden-menu');
        document.querySelector(
          '.submenu-wrapper'
        ).style.width = `${dom.clientWidth}px`;
        document.querySelector(
          '.submenu-wrapper'
        ).style.height = `${dom.clientHeight}px`;
        const menuList = document.querySelectorAll('.menu-item');
        const metric = menuList[menuIndex].getBoundingClientRect();

        const offsetWidth = metric.x + metric.width / 2 - 15;
        const containerLeft = Math.max(
          Math.min(
            Math.min((window.innerWidth - dom.clientWidth) / 2, 200),
            offsetWidth
          ),
          offsetWidth - dom.clientWidth / 2
        );
        const arrowLeft = offsetWidth - containerLeft;
        container.style.left = `${containerLeft}px`;
        arrow.style.left = `${arrowLeft}px`;
      } else {
        document.querySelector('.submenu-wrapper').style.width = `${0}px`;
        document.querySelector('.submenu-wrapper').style.height = `${0}px`;
      }
    } else {
      document.querySelector('.submenu-wrapper').classList.add('hidden-menu');
    }
  }, [menuIndex]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    scrollHandler();
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (size.width > 1200) {
      setMobileNav(false);
    }
  }, [size.width]);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
    setMenuIndex([-1, 0]);
  });

  const logo = (
    <>
      <Icon className="mobile" id="sm-icon" isImage />
      <Icon className="desktop" id={desktopLogoId} isImage />
    </>
  );

  return (
    <div
      style={
        mobileNav
          ? {
              position: 'fixed',
              top: 0,
              zIndex: 99,
              width: '100%',
              overflow: 'scroll',
            }
          : {}
      }
    >
      {announcementComponent && (
        <AnnouncementV2
          announcementComponent={announcementComponent}
          hideBar={changeNav}
        />
      )}
      <HeaderStyles
        isDark={isDarkTheme && !changeNav}
        open={open}
        scrolled={changeNav || mobileNav}
        changeNav={changeNav}
        onMouseEnter={() => handleHeader(true)}
        onMouseLeave={() => handleHeader(false)}
      >
        <Navbar
          expand="xl"
          className={`${changeNav && 'stickyNav'}`}
          style={{
            padding: 0,
          }}
          onToggle={e => handleToggle(e)}
        >
          <Container
            className={`header-container ${isExpanded ? 'expanded' : ''}`}
          >
            <div className={`brand-wrapper ${isExpanded ? 'expanded' : ''}`}>
              <Navbar.Brand>
                {disableLogoNavigation ? logo : <Link to="/">{logo}</Link>}
              </Navbar.Brand>
              <NavButtons className={changeNav ? 'scrolled' : ''}>
                {!mobileNav && (
                  <CallToAction
                    variant="primary"
                    className="headerCTA"
                    value="Request a Demo"
                    link="https://www.shopmonkey.io/demo"
                    size="lg"
                  />
                )}
                <NavToggle
                  mobileNav={mobileNav}
                  setMobileNav={setMobileNav}
                  setActiveDropdown={setActiveDropdown}
                />
              </NavButtons>
            </div>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-start mobile-menu"
            >
              <Nav ref={dropdownRef}>
                {menuData.menuItem.slice(0, 4).map((item, index) => {
                  if (item.childMenuItem.length === 0) {
                    return (
                      <Nav.Item
                        className={`menu-item responsive`}
                        key={item.internalName}
                      >
                        <div
                          className={clsx(
                            'nav-header',
                            open === String(index) && 'header-active',
                            menuIndex === index && 'hover-active-border'
                          )}
                          onMouseEnter={e => handleMenu(e, index)}
                          onMouseLeave={e => handleMenu(e, -1)}
                          role="button"
                          tabIndex={0}
                          aria-controls={`dropdown-content-${index}`}
                          aria-expanded={open === String(index)}
                          onClick={() =>
                            open === String(index)
                              ? setOpen(false)
                              : setOpen(String(index))
                          }
                        >
                          <Link
                            style={{ textTransform: 'none' }}
                            to={item.menuItemCallToAction.url}
                          >
                            <span
                              className={clsx(
                                'nav-header-item',
                                open === String(index) && 'header-item-active',
                                menuIndex === index && 'hover-item-active',
                                menuIndex === index && 'hover-active-border'
                              )}
                            >
                              {item.label}
                            </span>
                          </Link>
                        </div>
                      </Nav.Item>
                    );
                  }
                  return (
                    <Nav.Item
                      className={`menu-item responsive`}
                      key={item.internalName}
                    >
                      <div
                        className={clsx(
                          'nav-header',
                          open === String(index) && 'header-active',
                          menuIndex === index && 'hover-active-border'
                        )}
                        onMouseEnter={e => handleMenu(e, index)}
                        onMouseLeave={e => handleMenu(e, -1)}
                        role="button"
                        tabIndex={0}
                        aria-controls={`dropdown-content-${index}`}
                        aria-expanded={open === String(index)}
                        onClick={() =>
                          open === String(index)
                            ? setOpen(false)
                            : setOpen(String(index))
                        }
                      >
                        <span
                          className={clsx(
                            'nav-header-item',
                            open === String(index) && 'header-item-active',
                            menuIndex === index && 'hover-item-active'
                          )}
                        >
                          {item.label}
                        </span>
                      </div>
                      <Collapse in={open === String(index)}>
                        <div
                          id={`dropdown-content-${index}`}
                          className="dropdown-content mx-auto d-xl-none"
                        >
                          <DropdownContent item={item} setOpen={setOpen} />
                        </div>
                      </Collapse>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
            <div
              className="submenu-container d-none d-xl-block"
              onMouseEnter={() => handleMouseInSubmenu(true)}
              onMouseLeave={() => handleMouseInSubmenu(false)}
            >
              <div
                className={`site-header-arrow d-none d-xl-block ${
                  menuIndex === -1 ? 'arrow-hidden' : ''
                }`}
              />
              <div className="submenu-wrapper d-none d-xl-block">
                <AnimatePresence initial={false} custom={direction}>
                  <m.div
                    key={menuIndex}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      y: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.3 },
                    }}
                  >
                    {submenuList[menuIndex]}
                  </m.div>
                </AnimatePresence>
              </div>
            </div>
            <Nav className="removeBtn justify-self-end">
              {menuData.menuItem
                .slice(4, menuData.menuItem.length)
                .map((item, index) => {
                  if (item.childMenuItem.length === 0) {
                    return (
                      <Nav.Item
                        className={`menu-item responsive`}
                        key={item.internalName}
                      >
                        <div
                          className={clsx(
                            'nav-header',
                            open === String(index + 5) && 'header-active',
                            menuIndex === index + 5 && 'hover-active-border'
                          )}
                          onMouseEnter={e => handleMenu(e, index + 5)}
                          onMouseLeave={e => handleMenu(e, -1)}
                          role="button"
                          tabIndex={0}
                          aria-controls={`dropdown-content-${index}`}
                          aria-expanded={open === String(index)}
                          onClick={() =>
                            open === String(index)
                              ? setOpen(false)
                              : setOpen(String(index))
                          }
                        >
                          <Link
                            style={{ textTransform: 'none' }}
                            to={item.menuItemCallToAction.url}
                          >
                            <span
                              className={clsx(
                                'nav-header-item',
                                open === String(index) && 'header-item-active',
                                menuIndex === index && 'hover-item-active',
                                menuIndex === index && 'hover-active-border'
                              )}
                            >
                              {item.label}
                            </span>
                          </Link>
                        </div>
                      </Nav.Item>
                    );
                  }
                  return (
                    <Nav.Item
                      className={`menu-item responsive`}
                      key={item.internalName}
                    >
                      <div
                        className={clsx(
                          'nav-header',
                          open === String(index + 4) && 'header-active',
                          menuIndex === index + 4 && 'hover-active-border'
                        )}
                        onMouseEnter={e => handleMenu(e, index + 4)}
                        onMouseLeave={e => handleMenu(e, -1)}
                        role="button"
                        tabIndex={0}
                        aria-controls={`dropdown-content-${index}`}
                        aria-expanded={open === String(index)}
                        onClick={() =>
                          open === String(index)
                            ? setOpen(false)
                            : setOpen(String(index))
                        }
                      >
                        <span
                          className={clsx(
                            'nav-header-item',
                            open === String(index) && 'header-item-active',
                            menuIndex === index && 'hover-item-active'
                          )}
                        >
                          {item.label}
                        </span>
                      </div>
                      <Collapse in={open === String(index)}>
                        <div
                          id={`dropdown-content-${index}`}
                          className="dropdown-content mx-auto d-xl-none"
                        >
                          <DropdownContent item={item} setOpen={setOpen} />
                        </div>
                      </Collapse>
                    </Nav.Item>
                  );
                })}
              <CallToAction
                variant={isStripeCaptialPage ? 'secondary' : 'primary'}
                className={clsx(!changeNav && 'initialCta', 'headerCTA')}
                value={callToActionData.label}
                link={callToActionData.url}
                key={callToActionData.id}
                pop={popUpReference}
                size={callToActionData.size}
                icon={callToActionData.icon}
                componentName={componentName || callToActionData.__typename}
              />
            </Nav>
          </Container>
          <MobileNav
            data={menuData}
            mobileNav={mobileNav}
            scrolled={changeNav}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
          />
        </Navbar>
      </HeaderStyles>
    </div>
  );
};

const getData = graphql`
  {
    data: datoCmsHeaderComponent(internalName: { eq: "Global Header" }) {
      ...datoCmsHeaderComponent
    }
  }
`;

export default Header;
