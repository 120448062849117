import React from 'react';
import MobileNavItem from './MobileNavItem';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import { Container, Wrapper, List, Buttons } from '../styles/MobileNav.styled';

const MobileNav = ({
  data,
  mobileNav,
  scrolled,
  activeDropdown,
  setActiveDropdown,
}) => {
  const menuData = data?.menuItem;
  return (
    <Container
      scrolled={scrolled}
      className={mobileNav ? 'active' : ''}
      activeDropdown={activeDropdown}
    >
      <Wrapper>
        <List>
          {[...menuData.filter(i => i.internalName !== 'Sign In')]?.map(
            (item, index) => (
              <MobileNavItem
                key={item?.internalName}
                index={index}
                data={item}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
            )
          )}
        </List>
        <Buttons>
          <a href="https://app.shopmonkey.cloud/login">Sign In</a>
          <CallToAction
            variant="primary"
            className="demo-btn"
            value="Request a Demo"
            link="https://www.shopmonkey.io/demo"
            size="lg"
          />
        </Buttons>
      </Wrapper>
    </Container>
  );
};

export default MobileNav;
