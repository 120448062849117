import styled, { css } from 'styled-components';
import caret from '../../../images/caret.svg';
import { atMinWidth } from '../../atoms/breakpoints';
import shadow from '../../atoms/shadows';
import { font } from '../../atoms/typography';
import { at } from 'lodash';
import { colors } from '../../atoms/colors';
export default styled.div`
  .navbar {
    border-bottom: 1px solid transparent;
    position: relative;
    ${atMinWidth.lg`
      position: absolute;
    `}
    width: 100%;
    z-index: 10000;
    /* Match Mutiny banner timing */
    transition: top 0.1s cubic-bezier(0.300, 0.000, 0.175, 1.000) 0s;

    &.stickyNav {
      position: fixed;
      top: ${props => (props.withAnnouncement ? '32px' : 0)};
      background: #fff;
      z-index: 10001;
      background-color: ${props =>
        props?.open ? '#fff' : 'rgba(255, 255, 255, 0.9)'};
      backdrop-filter: blur(5px);
      box-shadow: 0px 12px 16px rgba(106, 114, 158, 0.1);

      .dropdown-content {
        top: 86px;
      }

      /* Allow space for the Mutiny banner if one is open */
      body:has([id*="mutiny-preact"] [class*="open"] > .mutiny-banner) #___gatsby & {
        @media (min-width: 720px) {
          top: 70px;
        }
      }
      @media (max-width: 768px) {
        min-height: 60px;
        top: ${props => (props.withAnnouncement ? '42px' : 0)};
        position: fixed;
        ${props =>
          props.changeNav &&
          css`
            top: 0px;
          `}
      }
    }

    @media (max-width: 1200px) {
      background-color: #fff !important;
    }

    @media (max-width: 768px) {
      min-height: 60px;
      top: ${props => (props.withAnnouncement ? '42px' : 0)};
      ${props =>
        props.changeNav &&
        css`
          top: 0px;
        `}
    }
  }

  .brand-wrapper {
    transition: box-shadow 200ms ease-in-out;

    ${atMinWidth.lg`
      margin-right: 40px;
    `}

    @media (max-width: 1199.98px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      top: 0;
      padding: 16px;
      background: #fff;

      .navbar-toggler {
        border: none;
      }
    }

    @media (max-width: 1199px) {
      height: 75px;
      padding: 12px 32px;
      align-items: center;
      ${props =>
        props?.scrolled &&
        css`
          ${shadow('lg')};
        `}
    }

    @media (max-width: 499px) {
      padding: 12px 23.5px;
    }
  }

  .header-container {
    max-width: 1700px;
    padding: 16px 32px;
    transition: all 0.2s;
    scroll-behavior: smooth;

    @media (max-width: 1199.98px) {
      max-height: 100vh;
      overflow-y: auto;
      padding: 0 0;
      padding-bottom: 0 !important;
    }

    #basic-navbar-nav {
      @media (max-width: 1200px) {
        margin-right: 0 !important;
      }

      @media (max-width: 1199.98px) {
        margin: 38px 0 0 !important;
      }
    }

    button {
      letter-spacing: 1.4px;
    }

    a[href*='/pricing']:not(.headerCTA) {
      ${font('overline', 'sm', '700')}
      text-decoration: none;
      opacity: 1;
     
      
      @media (max-width: 1366px) {
        margin: auto 0 !important;
      }
    }

    a:not(.headerCTA) {
      ${font('text', 'sm', '700')}
      color: ${props => (props.isDark ? '#fff' : 'var(--text-color)')};
      width: max-content;
      text-decoration: none;

      @media (max-width: 768px) {
        color: inherit;
      }

      &:after {
        font-size: 14px;
        color: var(--primary-color);
      }
    }

    .navbar-brand {
      width: 38px;
      height: 38px;
      padding: 0;

      ${atMinWidth.xs`
        width: 45px;
        height: 45px;
      `}

      ${atMinWidth.sm`
        width: 188px;
        height: 28px;
      `}

      ${atMinWidth.xl`
        width: auto;
        height: auto;
      `}

      a {
        display: block;
        width: 100%;
        height: 100%;

        .mobile {
          display: block;

          ${atMinWidth.sm`
            display: none;
          `}
        }

        .desktop {
          display: none;

          ${atMinWidth.sm`
            display: block;
          `}
        }

        svg {
          &:hover {
            opacity: 0.75;
          }
        }
      }

      svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          opacity: 1;

        ${atMinWidth.xl`
          width: 188px;
          height: 28px;
        `}
      }
    }

    .responsive {
      @media (max-width: 1199.98px) {
        margin-right: 10px !important;
      }
    }

    .menu-item {
    padding: 10px 12px;

      &.navLink {
        @media (max-width: 1336px) {
          padding: 10px 24px;
        }

        @media (min-width: 1200px) {
          position: relative;

          &::after {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${props =>
              props.isDark ? '#fff' : 'var(--text-color)'};
            position: absolute;
            left: 50%;
            bottom: -2px;
            transition: width 0.3s;
            transform: translateX(-50%);
            opacity: 0;
          }

          &:hover {
            opacity: 1;

            &::after {
              width: 100%;
              opacity: 1;
            }
          }
        }
      }

      @media (max-width: 1199.98px) {
        margin: 0 !important;
        border-bottom: 2px solid ${props =>
          props.isDark ? '#fff' : colors.gray[900]};

        &:first-child {
          border-top: 2px solid ${props =>
            props.isDark ? '#fff' : colors.gray[900]};
        }

        &.navLink {
          padding: 10px 16px;
          width: 100%;
        }

        .nav-header {
          padding: 12px 16px;
          line-height: 24px;
          width: 100%;
        }

        .dropdown-content {
          position: unset;
          box-shadow: unset;
          padding: 0 0 20px;

          .container {
            display: block;
            max-width: unset;

            .row {
              display: block;

              .nav-section {
                margin: 0;
                padding: 24px 24px 16px;
              }
            }

            .nav-section-items {
              display: block;

              .col {
                max-width: unset;
              }

              .nav-item {
                h6 {
                  ${font('text', 'sm', '400')}
                  margin: 0;
                }

                .subhead {
                  font-weight: 400;
                }
              }
            }

            .top-block {
              p {
                padding: 16px 0;
                margin-bottom: 16px;
                color: #7b848f;
                border-bottom: 1px solid #f0f2f5;
              }
            }
          }
        }
      }
    }

    .initialCta {
      opacity: 1;

      button {
        background-color: var(--primary-color) !important;
        border-color: transparent !important;
        width: 100%;
      }
    }

    .navbar-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .nav-header {
      position: relative;
      ${font('text', 'md', '500')}      
      color: ${colors.gray[900]};
      font-family: CircularXX;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.27px;


      .nav-header-item {
        white-space:nowrap;
        color: ${props => (props.isDark ? '#fff' : colors.gray[900])};
        font-family: CircularXX;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.27px;

        &.header-item-active {
          @media (max-width: 375px) {
            color: var(--primary-color);
          }
        }

        &.hover-item-active {
          @media (max-width: 375px) {
            color: var(--primary-color);
          }
        }
      }

      &:focus {
        outline: none;
      }

      @media (min-width: 1200px) {
        color: ${props => (props.isDark ? '#fff' : 'var(--text-color)')};

        &::after {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${props =>
            props.isDark ? '#fff' : 'var(--text-color)'};
          position: absolute;
          left: calc(50% - 10px);
          bottom: 4px;
          transition: width 0.3s;
          transform: translateX(-50%);
          opacity: 0;
        }

        &.hover-active {
          &::after {
            width: calc(100% - 76px);
            opacity: 1;
          }
        }

        &.hover-active-border {
       
          border-bottom: 2px solid  ${props =>
            props.isDark ? '#fff' : 'var(--Gray-900, #1D2030)'};
        }
      }

      @media (max-width: 1199.98px) {
        &::after {
          content: '';
          background-image: url(${caret});
          filter: ${props =>
            props.isDark
              ? 'invert(1)'
              : 'invert(15%) sepia(73%) saturate(4470%) hue-rotate(234deg) brightness(116%) contrast(111%)'};

          height: 9px;
          width: 12px;
          display: block;
          position: absolute;
          border: none;
          background-repeat: no-repeat;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          transition: all 0.2s;
        }
      }

      > span {
        letter-spacing: 1.4px;
      }
    }

    .header-active {
      color: ${props => (props.isDark ? '#fff' : 'var(--primary-color)')};

      @media (max-width: 1199.98px) {
        background: rgba(28, 63, 255, 0.05);

        &::after {
          top: 50%;
          right: 18px;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .removeBtn {
      .sign-in {

        @media (min-width: 1199.98px) {
          display: none;
        }
      }

      @media (max-width: 1199.98px) {
        display: none;
      }

      .separator {
        display: inline-block;
        width: 1px;
        height: 24px;
        margin: auto 12px;
        background: #c2c6cc;

        @media (max-width: 1199.98px) {
          display: none;
        }
      }
    }

    &.expanded {
      padding-bottom: 16px !important;

      .removeBtn {
        @media (max-width: 1199.98px) {
          display: flex;
          margin: 0 auto;
          width: 100%;
          max-width: 380px;

          button {
            width: 100%;
          }

          a {
            margin: 10px 0 !important;
            width: 100%;
            text-align: center;
            display: inline-block;
            text-decoration: none;

            &.phone-wrapper {
              display: none;
            }

            .btn {
              width: 100%;
            }
          }
        }
      }
    }

    .site-header-arrow {
      position: absolute;
      display: none !important;
      top: 2px;
      left: 50%;
      margin: 0 0 0 -6px;
      width: 25px;
      height: 25px;
      background-color: #fff;
      transform: translateX(-6px) translateY(12px) rotate(45deg);
      border-radius: 3px 0 0 0;
      box-shadow: -3px -3px 5px rgb(82 95 127 / 4%);
      transition: all 0.3s;
      z-index: -1;

      &.arrow-hidden {
        opacity: 0;
      }
    }

    .submenu-container {
      padding-top: 25px;
      position: absolute;
      top: 55px;
      transition: all 0.3s;
    }

    .submenu-wrapper {
      background-color: #fff;
      transform: perspective(0) rotateX(0deg);
      border-top: 1px solid #eee;
      transition: all 0.4s;
      box-shadow: 0px 8px 80px rgba(106, 114, 158, 0.2);
      overflow: hidden;
      border-radius: 12px;
      transform-origin: top;

      &.hidden-menu {
        opacity: 0;
        transform: perspective(100px) rotateX(-2deg);
        z-index: 0;
        pointer-events: none;

        &.d-xl-none {
          display: none !important;
        }
      }

      .dropdown-content {
        display: inline-block;
        position: absolute;
        background: #fff;
        overflow: hidden;
        padding: 0;
        border-radius: 12px;
        top: 0;
        left: 0;
      }
    }
  }

  .btn-outline-primary {
    border-color: #fff;
    background: transparent;
    color: #fff;

    &:hover {
      color: #fff !important;
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  .phone-wrapper {
    .phone-icon {
      font-size: 14px;
    }

    .call-label {
      margin-left: 4px;
    }

    .call-number {
      font-weight: 400;
    }
  }
`;

export const NavButtons = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;

  ${atMinWidth.xl`
    display: none;
  `}

  a {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
  }

  &.scrolled {
    a {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
