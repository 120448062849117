import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const DropdownStyles = styled.div`
  background-color: #fff;

  .col {
    max-width: 254px;
    min-width: 240px;
    margin: 35px 0 30px 35px;
    padding: 0;

    a {
      text-decoration: none;
    }

    &.article-item {
      max-width: 500px;
      padding: 0;
      border-radius: 5px;
    }
  }

  .nav-item {
    transition: all 0.2s;
    overflow: hidden;
    border-radius: 5px;

    h6 {
      ${font('text', 'lg', '500')}
      color: var(--dark-color);
      transition: all 0.2s;
    }

    .subhead {
      color: var(--subtle-text-color);
      ${font('text', 'sm', '400')}
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;

      h6 {
        color: var(--primary-color);
      }
    }
  }

  .nav-section {
    padding: 48px 48px 32px;

    &:last-of-type {
      margin-right: 0;
      margin-left: 0;
    }

    &.bg-surface {
      background: #f9fbfe;
    }

    .top-block {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        align-items: center;
      }

      p {
        ${font('overline', 'md', '700')}
        color: #7b848f;
        margin-bottom: 24px;
      }

      a {
        color: var(--subtle-text-color);
        font-size: 14.4px;
        text-transform: capitalize;

        @media (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }

    &-items {
      display: flex;

      &:last-child {
        margin-right: 0;
      }
    }

    .col {
      margin: 0;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .nav-item {
      display: flex;

      figure {
        display: flex;
        align-items: start;
        margin-bottom: 0;
        width: 32px;
        height: 32px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .full-image {
        width: 170px;
        height: 165px;
        margin-right: 0;

        .gatsby-image-wrapper {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .no-wrap {
    flex-wrap: nowrap !important;

    .nav-item {
      display: flex;

      figure {
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border-radius: 50%;
      }
    }
  }
  .story-wrapper {
    background: #f9fbfe;

    .nav-section {
      padding-top: 32px;
    }

    figure {
      margin-top: 5px;
    }
  }
`;

export const NavItemStyles = styled.div`
  margin-bottom: 24px;

  @media (max-width: 1199.98px) {
    margin-bottom: 20px;
  }

  &.mb-8 {
    margin-bottom: 8px;
  }

  .nav-item {
    border: ${props => (props.fullImage ? '0.5px solid #C2C6CC' : 'none')};

    &:first-of-type {
      margin-bottom: ${props => (props.fullImage ? '32px' : 0)};
    }

    .article-content {
      max-width: max-content;
      display: flex;
      align-items: center;

      .full-image {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        flex: 0 0 100px;
        overflow: hidden;
        margin-right: 24px;
      }

      .link-text,
      .link-text p {
        position: relative;
        ${font('text', 'xs', '400')}
        color: ${colors.gray[600]};
        text-transform: none;
      }

      svg {
        transition: all 250ms ease-in-out;
      }

      &:hover {
        svg {
          transform: translateX(4px);
        }
      }
    }

    .title {
      ${font('text', 'sm', '600')}
      color: var(--text-color);
      margin-top: 4px;
      margin-bottom: 2px;

      .title-icon {
        margin-left: 12px;
        font-size: 20px;
        transform: rotate(180deg);
        display: inline-block;
        opacity: 0;
        transition: all 250ms ease-in-out;
      }
    }

    .subhead-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .subhead, .subhead p, .subhead p a {
      ${font('text', 'xs', '500')}
      margin-bottom: ${props => (props.fullImage ? '24px' : 0)};
      color: ${colors.gray[600]};
      transition: all 250ms ease-in-out;
    }

    .subhead p a[href^="mailto"] {
      text-decoration: underline
    }

    &:hover {
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;

      .title {
        color: var(--dark-color);

        .title-icon {
          opacity: 1;
        }
      }

      .subhead {
        color: ${colors.gray[900]};
      }
    }
  }
`;

export const NavSectionGridStyles = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: ${props => `repeat(${props.count}, auto)`};
  grid-column-gap: 30px;

  > * {
    ${props =>
      props.featured
        ? `a {
            display: inline-block;
            width: auto;
          }`
        : `
          max-width: 254px;
          min-width: 240px;
    `}
  }
`;
