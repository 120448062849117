import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../styles/atoms/colors';
import { atMinWidth } from '../../styles/atoms/breakpoints';

const AnnouncementV2 = ({ announcementComponent, hideBar }) => {
  const [isOpen, setIsOpen] = useState(true);
  if (!isOpen || hideBar) return null;
  return (
    <Container backgroundColor={announcementComponent.backgroundColor.hex}>
      <div className="left-container">
        <Badge color={announcementComponent.badgeVariant}>
          <span>{announcementComponent.badgeTitle}</span>
        </Badge>
        <span className="title">{announcementComponent.title}</span>
        <AnchorButton
          href={announcementComponent.callToAction.url}
          color={announcementComponent.titleColor.hex}
          target="_blank"
        >
          <span>{announcementComponent.callToAction.label}</span>
        </AnchorButton>
      </div>
      <CloseButton onClick={() => setIsOpen(!isOpen)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke={announcementComponent.titleColor.hex}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </CloseButton>
    </Container>
  );
};

const CloseButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: none;
`;

const Container = styled.div`
  background-color: ${props => props.backgroundColor ?? '"#343643"'};
  color: ${props => props.titleColor};
  display: flex;
  width: 100%;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1000;
  gap: 16px;

  ${atMinWidth.md`
    align-items: center;
  `}

  .left-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;

    .title {
      color: var(--Base-White, #fff);
      font-family: CircularXX;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.16px;
    }
  }
`;

const AnchorButton = styled(Link)`
  background-color: transparent;
  color: ${props => (props.color ? props.color : colors.white)};
  border-radius: 8px;
  border: 1px solid var(--Base-White, ${props => props.color ?? colors.white});
  padding: 8px 16px;
  font-family: CircularXX;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  &:hover {
    background-color: ${props => props.color ?? colors.white};
    color: ${props => props.backgroundColor ?? colors.black};
  }
`;

const Badge = styled.div`
  background-color: ${props => BadgeColors[props.color].background};
  color: ${props => BadgeColors[props.color].text};
  height: 26px;
  padding: 0 12px;
  font-family: CircularXX;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  ${atMinWidth.lg`
    display: flex;
  `}
`;

const BadgeColors = {
  gray: {
    text: colors.gray[800],
    background: colors.gray[50],
  },
  purple: {
    text: colors.purple[900],
    background: colors.purple[100],
  },
  primary: {
    text: colors.primary[500],
    background: colors.primary[50],
  },
  green: {
    text: colors.green[700],
    background: colors.green[50],
  },
  yellow: {
    text: colors.yellow[900],
    background: colors.yellow[50],
  },
  aqua: {
    text: colors.aqua[900],
    background: colors.aqua[50],
  },
};

export default AnnouncementV2;
